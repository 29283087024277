import Navbar from "./layout/Navbar";
import {HelmetProvider} from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./layout/Footer";
import Products from "./pages/Products";
import Product from "./pages/Product";
import About from "./pages/About";
import Content from "./pages/Content";
import Siderbar from "./layout/Siderbar";
import { useState } from "react";
import ReactGA from "react-analytics-ga4";

ReactGA.initialize("G-X68R4S33H2");
function App() {
  const [open, setOpen] = useState(false)
  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <div className=" relative w-full">
            <Navbar setOpen={setOpen}/>
            {open &&  <Siderbar setOpen={setOpen}/>}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/products/:productSlug" element={<Products />} />
              <Route path="/product/:productSlug" element={<Product />} />
              <Route path="/about" element={<About />} />
              <Route path=":pageSlug" element={<Content />} />
            </Routes>

            <Footer />
          </div>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;