import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import parse from 'html-react-parser';
import ProductsCategories from "./../component/ProductCategories";
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

const Content = () => {
  const { pageSlug } = useParams();
  const navigate = useNavigate();
  const [homeContent, setHomeContent] = useState(null);
  const {language} = useApp();
  useEffect(() => {
    const getHomeContent = async () => {
      let headersList = {
        Accept: "*/*",
      };

      let response = await fetch(
        `https://kiosks.ee/wp/wp-json/io/v1/content/${pageSlug}?lang=${language}`,
        {
          method: "GET",
          headers: headersList,
        }
      );

      let data = await response.json();

      if(!data){
        navigate("/");
      }

 
      setHomeContent(data);
    };
    getHomeContent();
  }, [pageSlug, language, navigate]);

  return (
    <>
    <Helmet>
      <title>{ `${ homeContent?.title } | Kiosks.ee` }</title>
    </Helmet>
    <div className=" w-full">
      <ProductsCategories></ProductsCategories>
      <div className=" w-full rounded_px mt-4"  style={{ background: "#E3EAE3" }}>
        {homeContent?.image ?
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-20 items-center">
          <div className=" pl-3 lg:pl-32 pt-4 lg:pt-0">
            <h1 className=" text-3xl lg:text-5xl font-medium pt-2">{homeContent?.title}</h1>
            <p className=" font- py-6 content">{homeContent?.text && parse(homeContent?.text)}</p>
          </div>
          <img src={homeContent?.image} alt="" />
        </div>
        :
        <div className="container">
          <div className="sm:px-4 lg:px-8 2xl:px-0 py-12">
            <h1 className=" text-3xl lg:text-5xl font-medium pt-2">{homeContent?.title}</h1>
            <p className=" font- py-6 content">{homeContent?.text && parse(homeContent?.text)}</p>
          </div>
        </div>
        }
      </div>

    {homeContent?.blocks && 
       <>
      <div className="w-full px-8 pb-40 pt-40">
        <div className="container">
          <h1 className=" font-medium text-4xl pb-4">
            {homeContent?.blocks?.[0].title}
          </h1>
          <p className=" font- pb-20 lg:w-1/3">{homeContent?.blocks?.[0].text}</p>
          <div className=" w-full grid grid-cols-1 lg:grid-cols-3 gap-10">
            {homeContent?.blocks?.[0]?.members?.map((item, ind) => (
              <div key={ind} className=" px-10 pt-10 rounded_px"  style={{ background: "#E3DDD9" }}>
                <h4 className=" text-2xl font-medium pb-3">{item?.name}</h4>
                <p className="  ">{item?.title}</p>
                <p className="hover:underline"><a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                <p className="hover:underline pb-4"><a href={`tel:${item?.phone}`}>{item?.phone}</a></p>
                <img src={item?.image} alt="" />
              </div>
            ))}
          </div>
         
        </div>
      </div>
      </>
    }
    </div>
    </>
  );
};

export default Content;
