import { createContext } from "react";

interface AppContextInterface{
    language: string;
    content: any;
    changeLanguage: (l:string) => void;
}

const AppContext = createContext<AppContextInterface>({
    language: "en",
    content: {},
    changeLanguage: () => false,
});

export default AppContext;