import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ProductsCategories from "./../component/ProductCategories";
import { Helmet } from "react-helmet-async";
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

const Home = () => {
  const {content, language} = useApp();
  const [homeContent, setHomeContent] = useState(null);
  useEffect(() => {
    const getHomeContent = async () => {
      let headersList = {
        Accept: "*/*",
      };

      let response = await fetch(
        "https://kiosks.ee/wp/wp-json/io/v1/content/home?lang="+language,
        {
          method: "GET",
          headers: headersList,
        }
      );

      let data = await response.json();
      setHomeContent(data?.blocks);
    };
    getHomeContent();
  }, [language]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "ease-in-out",
  };

  return (
    <>
    <Helmet>
      <title>{ content.main_title }</title>
    </Helmet>
    <div className=" w-full">

    {homeContent?.[2]?.grid &&
      <ProductsCategories classExtra="" categories={homeContent?.[2]?.grid}></ProductsCategories>
    }
    
    <div className=" w-full lg:mt-20">
      
      <div
        className="w-full px-4 py-8 md:px-8 hero_section rounded_px bg-[#E3EAE3]"
      >
        <div className="container h-full">
          <div className=" w-full grid grid-cols-1 lg:grid-cols-2 md:gap-10 items-center h-full">
            <div className=" relative h-full flex items-start justify-center flex-col py-4 xl:pb-24">
              <h1
                className=" text-3xl lg:text-5xl font-medium lg:leading-[140%]"
                dangerouslySetInnerHTML={{ __html: homeContent?.[0]?.title }}
              ></h1>
              <p
                className=" pl-1 pt-4 leading-[160%]"
                dangerouslySetInnerHTML={{ __html: homeContent?.[0]?.text }}
              ></p>

              <div className=" lg:absolute bottom-10 w-full pt-4 lg:pt-0 ">
                <p className="text-sm flex items-center gap-2 scrollToSee transition-opacity duration-500 pl-6">
                  {" "}
                  <svg className="h-4 scrollDown" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 16"><path fill="#000" d="M5 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM2 4.8l2.9-3a.2.2 0 0 1 .2 0l3 3a.8.8 0 0 0 1.2-1.2L6 .4a1.5 1.5 0 0 0-2.2 0L.7 3.6A.8.8 0 0 0 2 4.8ZM8 11.2l-2.9 3a.2.2 0 0 1-.2 0l-3-3a.8.8 0 0 0-1.2 1.2L4 15.6a1.5 1.5 0 0 0 2.2 0l3.2-3.2A.8.8 0 0 0 8 11.2Z"/></svg>
                  {content.home.scrollToSee}
                </p>
              </div>
            </div>
            <div className=" relative hidden lg:block h-full">
              <img
                className=" absolute 2xl:-top-24 left-0 z-20"
                src={homeContent?.[0]?.image}
                alt=""
              />
            </div>
            <div className=" block lg:hidden h-full">
              <img
                className=" w-full  object-contain h-72"
                src={homeContent?.[0]?.image}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="  w-full pt-8 pb-8 md:py-36 px-4 md:px-8 lg:py-48"
        style={{ background: homeContent?.[1]?.attributes?.bgcolor }}
      >
        <div className="container">
          <div className=" w-full grid grid-cols-1 items-center lg:grid-cols-2 gap-10">
            <div>
              <h2
                className=" text-2xl lg:text-4xl font-medium lg:leading-[140%]"
                dangerouslySetInnerHTML={{ __html: homeContent?.[1]?.title }}
              ></h2>
              <p
                className="   pt-4"
                dangerouslySetInnerHTML={{ __html: homeContent?.[1]?.text }}
              ></p>
            </div>
            <div>
              <img src={homeContent?.[1]?.image} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className=" md:grid grid-cols-1 lg:grid-cols-2 gap-1">
        <div>
          <Link to={`/products/${homeContent?.[2]?.grid?.[0]?.slug}`}
            className=" block p-6 rounded_px h1_container overflow-hidden group" style={{ background: "#EAE5E3" }}>
            <p className=" font-medium text-xl lg:text-3xl pl-4">
              {homeContent?.[2]?.grid?.[0]?.title}
            </p>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex mt-4 pl-4">
              <p className=" cursor-pointer border-b pl-1 border-black">
                {content.home.findOutMore}
              </p>
            </div>
            <div className=" flex items-center justify-center h-full py-8 md:py-20 w-full">
              <img
                src={homeContent?.[2]?.grid?.[0]?.image}
                className=" object-contain h-full group-hover:scale-[1.1] transition duration-[600ms] max-h-[300px] md:max-h-[none]"
                alt=""
              />
            </div>
          </Link>
          <Link to={`/products/${homeContent?.[2]?.grid?.[1]?.slug}`}
            className=" block p-6 rounded_px h2_container mt-1 overflow-hidden group"
            style={{ background: "#E3EAEA" }}
          >
            <p className=" font-medium text-xl lg:text-3xl pl-4">
              {homeContent?.[2]?.grid?.[1]?.title}
            </p>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex mt-4 pl-4">
              <p className=" cursor-pointer border-b pl-1 border-black">
                {content.home.findOutMore}
              </p>
            </div>
            <div className=" flex items-center justify-center md:justify-end pr-9 h-full pb-3 w-full">
              <img
                src={homeContent?.[2]?.grid?.[1]?.image}
                className=" object-contain h-full group-hover:scale-[1.1] transition duration-[600ms] max-h-[300px] md:max-h-[none]"
                alt=""
              />
            </div>
          </Link>
        </div>
        <div>
        <Link to={`/products/${homeContent?.[2]?.grid?.[2]?.slug}`}
         className="block px-6 pt-6 md:p-6 rounded_px h2_container overflow-hidden group" style={{ background: "#DFDFDF" }}>
            <p className=" font-medium text-xl lg:text-3xl pl-4">
              {homeContent?.[2]?.grid?.[2]?.title}
            </p>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex mt-4 pl-4">
              <p className=" cursor-pointer border-b pl-1 border-black">
                {content.home.findOutMore}
              </p>
            </div>
            <div className=" flex items-end justify-end pt-0 md:pt-10 pr-9 h-full w-full">
              <img
                src={homeContent?.[2]?.grid?.[2]?.image}
                className="wall-shadow object-contain w-full h-full group-hover:scale-[1.1] transition duration-[600ms] max-h-[300px] md:max-h-[none]"
                alt=""
              />
            </div>
          </Link>
          <Link to={`/products/${homeContent?.[2]?.grid?.[3]?.slug}`}
            className="block p-6 rounded_px h1_container mt-1 overflow-hidden group"
            style={{ background: "#E3EAE3" }}
          >
            <p className=" font-medium text-xl lg:text-3xl pl-4">
              {homeContent?.[2]?.grid?.[3]?.title}
            </p>
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex mt-4 pl-4">
              <p className=" cursor-pointer border-b pl-1 border-black">
                {content.home.findOutMore}
              </p>
            </div>
            <div className=" flex items-start justify-center h-full py-8 md:py-20 w-full">
              <img
                src={homeContent?.[2]?.grid?.[3]?.image}
                className=" object-contain h-full group-hover:scale-[1.1] transition duration-[600ms] max-h-[300px] md:max-h-[500px]"
                alt=""
              />
            </div>
          </Link>
        </div>
      </div>
      <div className=" w-full my-8 lg:my-48">
        <div className="container">
          <h1 className=" font-medium text-2xl lg:text-4xl text-center">
            {homeContent?.[3]?.title}
          </h1>
          <div className=" mt-8 md:mt-20 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {homeContent?.[3]?.options?.map((item, ind) => (
              <div key={ind} className=" flex items-center justify-center gap-2 text-lg">
                <img src={item?.icon} className="h-8 w-8" alt="" />
                {item?.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className=" pb-20 pt-10 px-4 md:px-8"
        style={{ background: homeContent?.[4]?.attributes?.bgcolor }}
      >
        <div className="container">
          <div className=" w-full grid grid-cols-1 lg:grid-cols-3">
            <div>
              <h1
                className=" font-medium text-2xl lg:text-4xl lg:leading-[140%]"
                dangerouslySetInnerHTML={{ __html: homeContent?.[4]?.title }}
              ></h1>
              <p
                className="  pt-4"
                dangerouslySetInnerHTML={{ __html: homeContent?.[4]?.text }}
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full  h-full rounded_px">
        <Slider {...settings}>
          {homeContent?.[5]?.slides?.map((item, ind) => (
            <div key={ind} className=" relative w-full">
              <img className=" rounded_px w-full " src={item?.image.url} alt="" />
              <div className=" absolute top-10 left-10 z-20 ">
                <h1 className=" text-lg lg:text-2xl text-white  font-medium">
                  {item?.title}
                </h1>
                <p className=" text-sm lg:text-base text-white pt-2 lg:pt-4 font-medium">{item?.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div
        className=" w-full py-8 md:py-16 px-4 md:px-8 lg:py-48"
        style={{ background: homeContent?.[6]?.attributes?.bgcolor }}
      >
        <div className="container">
          <div className=" w-full grid grid-cols-1 lg:grid-cols-2 lg:gap-40">
            <h1 className=" text-2xl lg:text-4xl font-medium lg:leading-[140%]" dangerouslySetInnerHTML={{__html:homeContent?.[6]?.title}}></h1>
            <div>
              {homeContent?.[6]?.points?.map((item, ind)=>(
                <div key={ind} className=" pb-10">
                  <h1 className=" text-xl lg:text-3xl font-medium">{item?.title}</h1>
                  <p className=" text-sm lg:tex-lg  pt-6">{item?.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Home;
