import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ProductsCategories from "./../component/ProductCategories";
import {Helmet} from 'react-helmet-async';
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

const Products = () => {
  const [active, setActive] = useState("");
  const [homeContent, setHomeContent] = useState(null);
  const {content, language} = useApp();
  const {productSlug} = useParams()
  const navigate = useNavigate();
  useEffect(() => {
    setActive(productSlug)
    window.scrollTo(0,0)
    const getHomeContent = async () => {
      let headersList = {
        Accept: "*/*",
      };

      let response = await fetch(
        `https://kiosks.ee/wp/wp-json/io/v1/products/${productSlug}?lang=${language}`,
        {
          method: "GET",
          headers: headersList,
        }
      );

      let data = await response.json();

      if(!data){
        navigate("/");
      }

      data.cssSLUG = "BG"+data.slug.split('-')[0];
    
      setHomeContent(data);
    };
    if(active !== ""){
      getHomeContent();
    }
  }, [active, language, productSlug, navigate]);

  let cssSLUG = homeContent?.slug.substring(0, homeContent?.slug.indexOf('-'));

  return (
    <>
    <Helmet>
      <title>{ `${ homeContent?.title } | Kiosks.ee` }</title>
    </Helmet>
    <div className=" w-full">


    <ProductsCategories classExtra="shown"></ProductsCategories>


      <div className=" pt-8 pb-10 px-8">
        <div className="container">
          <div className=" w-full grid grid-cols-1 lg:grid-cols-5">
            <div className=" lg:col-span-2">
              <h1
                className=" font-medium text-2xl lg:text-4xl"
                dangerouslySetInnerHTML={{ __html: homeContent?.title }}
              ></h1>
              <p
                className=" font pt-4"
                dangerouslySetInnerHTML={{ __html: homeContent?.text }}
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-1 lg:grid-cols-3 gap-1">
        {homeContent?.products && homeContent?.products?.map((item, ind) => (
          <Link
          key={ind}
            to={"/product/" + item?.slug}
            className={`py-12 px-10 cursor-pointer rounded_px group bg-BG${cssSLUG}`}
          >
            <h1 className=" text-xl lg:text-3xl font-medium">{item?.title}</h1>
            <p className=" text-sm lg:text-lg pt-4 pb-12">{item?.text}</p>
            <div className=" flex items-start justify-center">
              <img src={item?.image} alt="" className="group-hover:scale-[1.1] transition duration-[600ms] max-h-[300px]" />
            </div>
          </Link>
        ))}
      </div>
      <div className=" py-32 ">
        <h1 className=" text-center w-full text-2xl lg:text-4xl font-medium">
          {content.home.moreKiosks}
        </h1>
        <div className="container">
          <div className=" w-full pt-16 grid grid-cols-1 lg:grid-cols-3 gap-1">
            {homeContent?.related_categories?.map((item, ind) => (
              <Link key={ind} to={`/products/${item?.slug}`} className=" relative w-full h-56 cursor-pointer rounded_px group overflow-hidden" style={{background:item?.attributes?.bgcolor}}>
                <img src={item?.image} className=" h-full pt-3 w-full object-contain group-hover:scale-[1.1] transition duration-[600ms]" alt="" />
                <div className=" absolute top-0 left-0 z-20 flex items-center justify-center w-full h-full" style={{background:"rgba(255,255,255, 0.5)"}}>
                  <h1 className=" text-2xl text-center font-semibold">{item?.title}</h1>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Products;
