import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

var lastScrollTop = 0;
window.addEventListener("scroll", function () {
var st = window.pageYOffset || document.documentElement.scrollTop;
if (st > lastScrollTop) {
    document.body.classList.remove("scrolled_up");
    document.body.classList.add("scrolled_down");
} else {
    document.body.classList.remove("scrolled_down");
    document.body.classList.add("scrolled_up");
}
if(st > 60){
  document.body.classList.add("scrolling");
}else{
  document.body.classList.remove("scrolling");
}
lastScrollTop = st <= 0 ? 0 : st;
}, false);

const slideDown = elem => elem.style.height = `${elem.scrollHeight+16}px`;

const showHideProductsNav = (e) => {
  e.preventDefault()
  var productsNav = document.getElementById("productNAV");
  var productsNavInner = document.getElementById("productNavInner");
  if(productsNav && productsNavInner){
    window.scrollTo(0, 0);
    if(productsNav){
      slideDown(productsNav);
      productsNav.classList.add("shown");
    }
    productsNavInner.classList.add("see");
      setTimeout(() => {
        productsNavInner.classList.remove("see");
      }, 400)
  }
};

const Navbar = ({
  

  setOpen}) => {

    // const handleClick = (e) => {
    //   e.preventDefault()
    // }
    const {content, language, changeLanguage} = useApp();


  return (
    <div className=" w-full header sticky top-0 bg-white z-50">
      <div className="container px-8 2xl:px-0">
        <div className=" w-full flex items-center justify-between stickyPadding py-4">
        <Link to="/">  <img src={`${process.env.PUBLIC_URL}/images/kiosks_logo2.png`} className="h-6 md:h-8 object-contain" alt="" /></Link>
          <div className=" hidden lg:flex items-center gap-4 xl:gap-8">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "text-sr font-medium  cursor-pointer" : "font-medium hover:text-sr transition cursor-pointer"
              }
            >
              {content.navbar.home}
            </NavLink>
            <NavLink
              onClick={showHideProductsNav}
              id="products-nav"
              // onClick={handleClick}
              to="/products/freestanding-kiosks"
              className={({ isActive }) =>
                isActive ? "text-sr font-medium  cursor-pointer" : "font-medium hover:text-sr transition cursor-pointer"
              }
            >
              {content.navbar.products}
            </NavLink>
            <NavLink
              to="/kiosk-development"
              className={({ isActive }) =>
                isActive ? "text-sr font-medium  cursor-pointer" : "font-medium hover:text-sr transition cursor-pointer"
              }
            >
              {content.navbar.kdevelopment}
            </NavLink>
            <NavLink
              to="/sitekiosk-software"
              className={({ isActive }) =>
                isActive ? "text-sr font-medium  cursor-pointer" : "font-medium hover:text-sr transition cursor-pointer"
              }
            >
              {content.navbar.sitekiosk}
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? "text-sr font-medium  cursor-pointer" : "font-medium hover:text-sr transition cursor-pointer"
              }
            >
              {content.navbar.about}
            </NavLink>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                isActive ? "text-sr font-medium  cursor-pointer" : "font-medium hover:text-sr transition cursor-pointer"
              }
            >
              {content.navbar.contact}
            </NavLink>
            <a href="mailto:info@kiosks.ee">
              <button className=" px-3 py-1 bg-pr rounded-2xl font-medium hover:bg-srhover">
                {content.navbar.request}
              </button>
            </a>
            <div className="langague-selector flex text-gray-4 items-center md:p-2 cursor-pointer">
              <div className="md:w-[60px] relative text-gray-4 flag-dropdown-anchor flex items-center align-center rounded-t undefined">
                {language === 'en' ?
                
                <button onClick={()=>changeLanguage("et")} className="flex items-center w-full hover:text-[#545EC4]"><div><svg className="w-5 h-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480"><g fillRule="evenodd"><rect width="640" height="477.9" rx="0" ry="0"/><rect width="640" height="159.3" y="320.7" fill="#fff" rx="0" ry="0"/><path fill="#1291ff" d="M0 0h640v159.3H0z"/></g></svg></div><p className="hidden sm:block font-medium leading-loose tracking-[0.6px] uppercase mx-2">et</p></button>
              
                :

                <button onClick={()=>changeLanguage("en")} className="flex items-center w-full hover:text-[#545EC4]"><div><svg className="w-5 h-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480"><path fill="#012169" d="M0 0h640v480H0z"/><path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/><path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/><path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/><path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/></svg></div><p className="hidden sm:block font-medium leading-loose tracking-[0.6px] uppercase mx-2">en</p></button>

                }

              </div>
            </div>
          </div>
          <div onClick={()=>setOpen(true)} className=" block lg:hidden text-black text-3xl cursor-pointer pr-2">
          ☰
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
