
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useApp } from "../context/AppContext/AppContextProvider.tsx"


const ProductsCategories = ({classExtra = ""}) => {
  const {language} = useApp();
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    const getProdCategories = async () => {
      let headersList = {
        Accept: "*/*",
      };

      let response = await fetch(
        `https://kiosks.ee/wp/wp-json/io/v1/productcats?lang=${language}`,
        {
          method: "GET",
          headers: headersList,
        }
      );

      let data = await response.json();
 
      setCategories(data);
    };
    getProdCategories();
  }, [language]);

  const [active, setActive] = useState("");
  return (
    <>
    {categories &&
      <div id="productNAV" className={`${classExtra} bg-white z-50`}>
        <div id="productNavInner" className="productNavInner">
        <div className=" grid grid-cols-1 lg:grid-cols-4 gap-1 overflow-hidden">
          {categories && categories.map((item, ind) => (
            <Link key={ind} to={`/products/${item.slug}`}
              className="flex flex-col justify-between px-3 pt-3 cursor-pointer rounded_px group"
                onClick={() => setActive(item.slug)}
                style={
                  active === item.slug
                    ? { background: item.attributes.bgcolor, border: "6px solid #D1EEFA" }
                    : { background: item.attributes.bgcolor, border: "6px solid transparent" }
                }
              >
              <p className=" font-medium">{item.title}</p>
              <div className=" w-full h-full flex items-end justify-center">
                <img src={`${item.thumbnail}`} className={`pt-6 px-3 group-hover:scale-[1.1] transition-scale duration-[600ms] relative mb-[-6px] filter ${active && active !== item.slug && 'blur-[1px]'} ${active === item.slug && 'blur-none scale-[1.1]'} hover:blur-none blur-none`} alt="" />
              </div>
              
            </Link>
          ))}
        </div>
        </div>
      </div>
    }
    </>
  );
};

export default ProductsCategories;
