import React from "react";
import {  NavLink } from "react-router-dom";
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

const slideDown = elem => elem.style.height = `${elem.scrollHeight+16}px`;
// const slideDown = elem => elem.style.height = "auto";

const showHideProductsNav = (e) => {
  e.preventDefault()
  var productsNav = document.getElementById("productNAV");
  window.scrollTo(0, 0);
  if(productsNav){
    slideDown(productsNav);
    productsNav.classList.add("shown");
  }
};


const Siderbar = ({setOpen}) => {

  const {content, language, changeLanguage} = useApp();

  return (
    <div className=" w-4/5 bg-white shadow-md min-h-screen fixed lg:hidden right-0 top-0 z-50 pt-10">
        <h1 onClick={()=>setOpen(false)} className=" text-3xl cursor-pointer w-full pl-10 ">✖</h1>
      <div className=" flex flex-col lg:hidden items-start gap-5 pt-10 px-10">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "text-sr font-medium  text-lg " : "font-medium text-lg"
          }
        >
          {content.navbar.home}
        </NavLink>
        <NavLink
          onClick={(event) => { showHideProductsNav(event); setOpen(false);}}
          id="products-nav"
          // onClick={handleClick}
          to="/products/freestanding-kiosks"
          className={({ isActive }) =>
            isActive ? "text-sr font-medium  cursor-pointer text-lg" : "font-medium text-lg cursor-pointer"
          }
        >
          {content.navbar.products}
        </NavLink>
        <NavLink
          to="/kiosk-development"
          className={({ isActive }) =>
            isActive ? "text-sr font-medium   text-lg" : "font-medium text-lg"
          }
        >
          {content.navbar.kdevelopment}
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive ? "text-sr font-medium   text-lg" : "font-medium text-lg"
          }
        >
          {content.navbar.about}
        </NavLink>
        <NavLink
          to="/contact-us"
          className={({ isActive }) =>
            isActive ? "text-sr font-medium   text-lg" : "font-medium text-lg"
          }
        >
          {content.navbar.contact}
        </NavLink>
        <button className=" px-3 py-1 bg-pr rounded-2xl font-medium">
          {content.navbar.request}
        </button>

          <div className="langague-selector flex text-gray-4 items-center md:p-2 py-4 cursor-pointer border-t w-full">
              <div className="md:w-[60px] relative text-gray-4 flag-dropdown-anchor flex items-center align-center rounded-t undefined">
                {language === 'en' ?
                
                <button onClick={()=>changeLanguage("et")} className="flex items-center w-full hover:text-[#545EC4]"><div><svg className="w-5 h-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480"><g fillRule="evenodd"><rect width="640" height="477.9" rx="0" ry="0"/><rect width="640" height="159.3" y="320.7" fill="#fff" rx="0" ry="0"/><path fill="#1291ff" d="M0 0h640v159.3H0z"/></g></svg></div><p className="font-medium leading-loose tracking-[0.6px] mx-2">Eesti keeles</p></button>
              
                :

                <button onClick={()=>changeLanguage("en")} className="flex items-center w-full hover:text-[#545EC4]"><div><svg className="w-5 h-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480"><path fill="#012169" d="M0 0h640v480H0z"/><path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/><path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/><path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/><path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/></svg></div><p className="font-medium leading-loose tracking-[0.6px] mx-2">In English</p></button>

                }

              </div>
            </div>
      </div>
    </div>
  );
};

export default Siderbar;
