
import {ReactNode, useContext, useEffect, useRef, useState } from "react";
import AppContext from "./AppContext.tsx";
import en from "../../data/translations/en.json"
import et from "../../data/translations/et.json";
// import sv from "../../data/translations/sv.json";

const AppContextProvider = ({children}:{children:ReactNode}) => {

    const [content, setContent] = useState<any>(getTranslation());
    const [language, setLanguage] = useState<string>(getLanguage());
    const isRunned = useRef<boolean>(false);

    const changeLanguage = (l:string) => {
        localStorage.setItem("kiosks_language", l);
        localStorage.setItem("kiosks_language_set_date", new Date().toString());
        setLanguage(l);
        setContent(getTranslation());
    }
    
    useEffect(() => {
        if(!isRunned.current){
            isRunned.current = true;

            // Parse the URL to get the 'lang' query parameter.
            const searchParams = new URLSearchParams(window.location.search);
            const urlLang = searchParams.get('lang');

            // If 'lang' parameter exists and is either 'en' or 'et', change the language.
            if (urlLang && (urlLang === 'en' || urlLang === 'et')) {
                changeLanguage(urlLang);
            }

            const kiosks_language_set_date = localStorage.getItem("kiosks_language_set_date");
            if(kiosks_language_set_date){
                const date = new Date(kiosks_language_set_date);
                const now = new Date();
                const diff = Math.abs(now.getTime() - date.getTime());
                const diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
                if(diffDays >= 90){
                    changeLanguage("en");
                }
            }
  
        return () => {
            isRunned.current = true;
        }

        }
    }, [])


    return (
        <AppContext.Provider
        value={{
            language,
            content,
            changeLanguage,
        }}
        >
        {children}
        </AppContext.Provider>
    );
}

export default AppContextProvider;

const getTranslation = () => {
    const language = localStorage.getItem("kiosks_language");
    if(language){
        if(language === "et"){
            return et;
        }
        // else if(language === "sv"){
        //     return sv;
        // }
        else if(language === "en"){
            return en;
        }
    }
    return en;
}

const getLanguage = () => {
    const language = localStorage.getItem("kiosks_language");
    if(language){
        return language;
    }
    return "en";
}

export const useApp = () => {
    return useContext(AppContext)
}