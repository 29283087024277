import React from "react";
import { Link } from "react-router-dom";
import { useApp } from "../context/AppContext/AppContextProvider.tsx"

const Footer = () => {
  const {content} = useApp();
  return (
    <div className=" w-full bg-black py-14 rounded mt-2 mb-1">
      <div className="container px-8 2xl:px-0">
        <div className=" grid grid-cols-1 px-0 lg:grid-cols-4 gap-6 md:gap-16">
          <div className=" flex flex-col gap-2">
            <span className=" text-white ">
              {content.footer.companyName}: Kiosks.ee OÜ
            </span>
            <span className=" text-white ">
              {content.footer.address}: Artelli 10c, Tallinn 10621, Estonia
            </span>
            <span className=" text-white ">
              {content.footer.registrycode}: 16459002
            </span>
          </div>
          <div className=" flex flex-col gap-2">
          <span className=" text-white ">
            {content.footer.vatcode}: EE102475261
            </span>
            <span className=" text-white ">
            {content.footer.bankaccount}: EE477700771007690933
            </span>
            <span className=" text-white ">
              {content.footer.bankswift}: LHVBEE22
            </span> 
          </div>
          <div className=" flex flex-col gap-2">
            <p className=" text-white ">{content.footer.email}:&nbsp;
              <a className="hover:underline" href="mailto:info@kiosks.ee">info@kiosks.ee</a>
            </p>
            <p className=" text-white ">{content.footer.phone}:&nbsp;
              <a className="hover:underline" href="tel:+3725299959">+372 5657 6000</a>
            </p>
          </div>
          <div className=" flex flex-col items-start lg:items-end gap-3">
            <a href="mailto:info@kiosks.ee">
              <button className=" px-3 py-1 bg-pr rounded-2xl font-medium hover:bg-srhover">
                {content.navbar.request}
              </button>
            </a>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row md:justify-between md:items-end">
        <p className=" text-xs text-gray-300 pt-12 col-span-2">
              © Kiosks.ee OÜ {new Date().getFullYear()} · {content.footer.rights}
              <Link to="/privacy" className="ml-8 text-xs text-white hover:underline">
                {content.footer.privacy}
              </Link>
            </p>
            <Link to="/eas/" className="mt-10 md:mt-0 text-xs text-white hover:underline">
              <img src={`${process.env.PUBLIC_URL}/images/el_regionaalarengu_fond_horisontaalne.jpg`} className="w-[200px] bg-white" alt="EAS" />
            </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
